import React from "react"
import AddOfferForm from "../../components/add-offer-form/AddOfferForm"

function NewOfferPage() {
  return (
    <div className="container">
      <div className="headerSpace"></div>
      <h1>Ett nytt erbjudande</h1>
      <AddOfferForm />
    </div>
  )
}

export default NewOfferPage
