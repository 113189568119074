import { React, useState, useEffect, useRef } from 'react'
import styles from './dotsDropdownMenu.module.scss'
import dots from './images/dots.svg'

function DotsDropdownMenu({openedForm}) {

  // Determine if the menu is open or closed.
  const [open, setOpen] = useState(false)

  // Closes or opens the menu.
  const toggleMenu = () => {
    setOpen(!open)
  }

  /**
   * Menu reference.
   */
  let menuRef = useRef()

  useEffect(() => {
    /**
     * Closes the menu if the user clicks outside.
     * @param {object} event - Mouse down event.
     */
    const handler = (event) =>{
      if (!menuRef.current.contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      // Remove event listener when component is unmounted.
      document.removeEventListener('mousedown', handler)
    }
  },[])

  return (
    <div className={styles.menuContainer}>
      <button className={styles.dotsBtn} onClick={ toggleMenu }>
        <img src={dots} alt=''/>
      </button>
      <div ref={menuRef} style={{visibility: open? 'visible': 'hidden'}} className={styles.menu} onClick={toggleMenu}>
        <button className={styles.menuItem} onClick={() => openedForm('visitForm')}
        >
          Nytt besök
        </button>
        <button
          className={styles.menuItem} 
          onClick={() => openedForm('discountForm')}
        >
          Lägg till rabatt
        </button>
      </div>
    </div>
)
}

export default DotsDropdownMenu
