import React from 'react'
import ClientSearch from '../../components/client-search/ClientSearch'

const ClientSearchPage = () => {
  return (
    <div className="containerFullScreen">
      <ClientSearch />
    </div>
  )
}

export default ClientSearchPage
