import styles from './waitingList.module.scss'
import { ReactComponent as PersonIcon } from './images/personIcon.svg'
import { ReactComponent as DeleteIcon } from './images/deleteIcon.svg'
import PropTypes from 'prop-types'

const WaitingList = ({ waitingList, bigScreen, removeFromQueue }) => {
  // In big screens the component adjusts the size
  // of the elements to the with of the screen.

  const containerStyle = bigScreen ? styles.listBS : styles.list
  return (
    <ul className={containerStyle}>
      {waitingList?.map((client) => (
        <li className={styles.item} key={client.id}>
          <PersonIcon />
          <h2 className={styles.name}>{client.name}</h2>
          <button
            className={styles.deleteIcon}
            onClick={() => {
              removeFromQueue(client.docId)
            }}
          >
            <DeleteIcon />
          </button>
          <span className={styles.number}>
            {waitingList?.indexOf(client) + 1}
          </span>
        </li>
      ))}
    </ul>
  )
}

WaitingList.prototype = {
  bigScreen: PropTypes.bool,
  waitinglist: PropTypes.array,
}

WaitingList.defaultProps = {
  bigScreen: false,
  waitinglist: [],
}

export default WaitingList
