import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import useStorage from '../../custom-hooks/useStorage'
import { useNavigate } from 'react-router-dom'
import styles from './addOfferForm.module.scss'
import ErrorMessage from '../error-message/ErrorMessage'
import CustomButton from '../custom-button/CustomButton'
import FileUploader from '../file-uploader/FileUploader'

function AddOfferForm() {
  // Field vaule states.
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [expiration, setExpiration] = useState('')

  // loading and Errors states.
  const [loading, setLoading] = useState(false)
  const [titleError, setTitleError] = useState(null)
  const [descriptionError, setDescriptionError] = useState(null)
  const [expirationError, setExpirationError] = useState(null)
  const [imageError, setImageError] = useState(null)
  const [fetchingError, setFetchingError] = useState(null)

  // User token.
  let { getIdToken } = useAuth()
  // Browser navigate
  let navigate = useNavigate()
  // Use storage.
  const {
    setFile,
    setFileName,
    setPath,
    setIsResizedImage,
    state: loadingState,
    progress: loadingProgress,
    url: imageUrl,
    error: imageLoadingError,
  } = useStorage()
  // Today's date.
  const today = new Date().toISOString().substring(0, 10)

  /**
   * Runs when the form is submitted.
   * @param {object} event - Submission event.
   */
  const handleSubmit = (event) => {
    event.preventDefault()
    if (!validate()) return
    addNewOffer()
  }

  /**
   * Uploads chosen image.
   * @param {object} event - On change event.
   */
  const uploadImage = (event) => {
    // Set file name the uploaded file name.
    setFileName(event.target.value?.split(/(\\|\/)/g).pop())
    // Set file and storage path.
    setPath('public/offers/images/')
    // Set that the returned image is resized.
    setIsResizedImage(true)
    setFile(event.target.files[0])
  }

  /**
   * Adds a new discount.
   */
  const addNewOffer = async () => {
    setLoading(true)

    try {
      const offer = {
        title,
        description,
        imageUrl,
        expiration,
      }

      const response = await fetch(`${process.env.REACT_APP_API_LINK}offers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await getIdToken()}`,
        },
        body: JSON.stringify(offer),
      })

      setLoading(false)

      if (!response.ok) {
        throw new Error()
      }

      const addedOffer = await response.json()
      console.log(addedOffer)

      // Reset fields values.
      resetValue()

      navigate('/offers')
    } catch (error) {
      setLoading(false)
      setFetchingError('Något gick fel, vänligen försök igen senare')
    }
  }

  /**
   * Validates user input.
   * @returns {boolean} - Validation results.
   */
  const validate = () => {
    let validInput = true
    setTitleError(null)
    setDescriptionError(null)
    setExpirationError(null)
    setImageError(null)
    setFetchingError('')

    if (!title) {
      setTitleError('Ange rubrik')
      validInput = false
    }
    if (!description) {
      setDescriptionError('Ange beskrivning')
      validInput = false
    }
    if (!expiration) {
      setExpirationError('Vänligen ange utgångsdatum')
      validInput = false
    }
    if (!imageUrl) {
      setImageError('Vänligen ladda upp en bild')
      validInput = false
    }
    if (imageLoadingError) {
      setImageError('Bilden kunde inte laddas')
      validInput = false
    }
    return validInput
  }

  /**
   * Resets fields values.
   */
  const resetValue = () => {
    setTitle('')
    setDescription('')
    setExpiration('')
    setFileName('')
    setPath('')
    setFile(null)
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <label htmlFor="titel" className={styles.label}>
        Rubrik
      </label>
      <input
        type="text"
        id="title"
        value={title}
        placeholder="t.ex. Köp 2 betala för en "
        maxLength="100"
        onChange={(e) => setTitle(e.target.value)}
      />
      <ErrorMessage message={titleError} />

      <label htmlFor="description" className={styles.label}>
        Beskrivning
      </label>
      <textarea
        id="title"
        value={description}
        maxLength="200"
        onChange={(e) => setDescription(e.target.value)}
      />
      <ErrorMessage message={descriptionError} />

      <label htmlFor="expiration" className={styles.label}>
        Utgår
      </label>
      <input
        type="date"
        id="expiration"
        value={expiration}
        min={today}
        onChange={(e) => setExpiration(e.target.value)}
      />
      <ErrorMessage message={expirationError} />

      <FileUploader
        lable="Välj en bild"
        onFileChosen={uploadImage}
        state={loadingState}
        progress={loadingProgress}
        acceptedTypes=".jpg"
      />
      <ErrorMessage message={imageError} />

      <ErrorMessage message={fetchingError} />

      <CustomButton
        text="Addera"
        theme="golden"
        pending={loading}
        onClick={handleSubmit}
      />
    </form>
  )
}

export default AddOfferForm
