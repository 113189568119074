import { useState, useEffect } from 'react'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'

export default function useStorage() {
  const [path, setPath] = useState('')
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [isResizedImage, setIsResizedImage] = useState(true)
  const [progress, setProgress] = useState(0)
  const [state, setState] = useState('stoped')
  const [error, setError] = useState('')
  const [url, setUrl] = useState('')

  // Get storage.
  const storage = getStorage()
  // Points to a folder in the storage.
  const pathRef = ref(storage, path)
  // Points to a file in the storage.
  const fileRef = ref(pathRef, fileName)

  const upload = async () => {
    const uploadTask = uploadBytesResumable(fileRef, file)
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events.
        // Set task progress, including the number of bytes uploaded and the total number of bytes to be uploaded.
        setProgress(
          Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        )
        setState(snapshot.state)
      },
      (error) => {
        // Handle unsuccessful uploads
        setError(error)
        setState('failed')
      },
      async () => {
        // Handle successful uploads on complete and get the download URL.
        setState('uploaded')
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
        if (!isResizedImage) {
          return setUrl(downloadURL)
        }
        // If the resize image is on change the url accordingly.
        const fileNameWithoutExtanion = fileName.replace(/\.[^/.]+$/, '')
        const resizedImageUrl = downloadURL.replace(
          fileNameWithoutExtanion,
          fileNameWithoutExtanion + '_800x700'
        )
        setUrl(resizedImageUrl)
      }
    )
  }

  useEffect(() => {
    if (!file || !path || !fileName) {
      setState('')
      setUrl('')
      setError('')
      return
    }
    upload()
  }, [file, path, fileName])

  return {
    setFile,
    setFileName,
    setPath,
    setIsResizedImage,
    setState,
    setUrl,
    state,
    progress,
    url,
    error,
  }
}
