import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../config/firebase'
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  // State declaration.
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(sessionStorage.getItem('user'))
  )
  const [authLoading, setAuthLoading] = useState(true)

  /**
   * Signs a new user.
   * @param {string} email - user email.
   * @param {string} password - user password.
   * @returns
   */
  function signIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  /**
   * Gets user ID token.
   */
  const getIdToken = async () => {
    return auth.currentUser?.getIdToken()
  }

  useEffect(() => {
    // Update the user when auth state changes.
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // store the user on session storage
        sessionStorage.setItem('user', JSON.stringify(user))
      } else {
        // removes the user from session storage on logOut
        sessionStorage.removeItem('user')
      }
      setAuthLoading(false)
      // Update current user.
      setCurrentUser(user)
    })
    // Stop observing changes when the context is removed.
    return unsubscribe
  }, [])

  /**
   * Signs user out.
   */
  async function signOut() {
    auth.signOut()
  }

  /**
   * The value provided by this context.
   */
  const value = {
    currentUser,
    getIdToken,
    signIn,
    signOut,
    authLoading,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
