import React, {useEffect, useRef} from 'react'
import styles from './customSelect.module.scss'

function CustomSelect({setValue}) { 

  // Get reference to select element.
  const select = useRef(null)

  // Set the value when the component mounts.
  useEffect(() => {
    setValue(select.current.value)
  }, [])

  return (
    <select 
      className={styles.customSelect} 
      ref={select}
      onChange={(e)=>setValue(e.target.value)}
      defaultValue="Klippning"
    >
      <option value="Klippning">Klippning</option>
      <option value="Klippning och skäggvård">Klippning och skäggvård</option>
      <option value="Skäggvård">Skäggvård</option>
      <option value="Ansiktebehandling">Ansiktebehandling</option>
    </select>
  )
}

export default CustomSelect
