import styles from './discountList.module.scss' 
import DiscountCard from '../discount-card/DiscountCard'


const DiscountList = ({discounts, onActivate}) => {

  /**
   * Contains only active disconts.
   */
  const activeDiscounts = discounts?.filter( 
    discount => discount.state === 'active'
  )

  return (
    <>
    <h2 className={styles.title}>Rabatter</h2>
    {(activeDiscounts?.length > 0)
      ?<ul className={styles.discounts}>
        {activeDiscounts?.map( discount => (
          <DiscountCard key={discount._id} {...discount} onActivate={onActivate} /> 
        ))}
      </ul>
      :<span className={styles.emptyMsg}>Det finns inga rabatter add visa </span>
    }
    </>
  )
}

export default DiscountList
