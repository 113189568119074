import { React, useState } from 'react'
import ToggleTwoValuesInput from '../toggle-two-values-input/ToggleTwoValuesInput'
import { useParams } from 'react-router'
import { useAuth } from '../../contexts/AuthContext'
import styles from './addDiscountForm.module.scss'
import ErrorMessage from '../error-message/ErrorMessage'
import CustomButton from '../custom-button/CustomButton'

function AddDiscountForm({ open, openedForm, addDiscountToList, clinetId }) {
  // Field vaule states.
  const [value, setValue] = useState('')
  const [valueType, setValueType] = useState('%')
  const [expiration, setExpiration] = useState('')
  const [comment, setComment] = useState('')

  // loading and Errors states.
  const [loading, setLoading] = useState(false)
  const [valueError, setValueError] = useState(null)
  const [expirationError, setExpirationError] = useState(null)
  const [fetchingError, setFetchingError] = useState(null)

  // Client Id.
  const { clientId } = useParams()
  // User token.
  let { getIdToken } = useAuth()
  // Today's date.
  const today = new Date().toISOString().substring(0, 10)

  /**
   * Runs when the form is submitted.
   * @param {object} event - Submission event.
   */
  const handleSubmit = (event) => {
    event.preventDefault()
    if (!validate()) return
    addNewDiscount()
  }

  /**
   * Adds a new discount.
   */
  const addNewDiscount = async () => {
    setLoading(true)

    try {
      const discount = {
        type: 'gift',
        value,
        valueType,
        expiration,
        comment,
        clientId,
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_LINK}discounts/?clientid=${clinetId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await getIdToken()}`,
          },
          body: JSON.stringify(discount),
        }
      )

      setLoading(false)

      if (!response.ok) {
        throw new Error()
      }

      const addedDiscount = await response.json()
      addDiscountToList(addedDiscount)

      // Reset fields values.
      resetValue()
      // Close the form.
      openedForm('')
    } catch (error) {
      setFetchingError('Något gick fel, vänligen försök igen senare')
    }
  }

  /**
   * Validates user input.
   * @returns {boolean} - Validation results.
   */
  const validate = () => {
    let validInput = true
    setValueError(null)
    setExpirationError(null)

    if (!value || value <= 0) {
      setValueError('Ange rabatvärdet')
      validInput = false
    }
    if (valueType === '%' && value > 100) {
      setValueError('Procentsats kan inte överstiger 100%')
      validInput = false
    }
    if (valueType !== '%' && value > 9999) {
      setValueError('Rabaten kan inte överstiger 9999kr')
      validInput = false
    }
    if (!expiration) {
      setExpirationError('Vänligen ange utgångsdatum')
      validInput = false
    }
    return validInput
  }

  /**
   * Resets fields values.
   */
  const resetValue = () => {
    setValue('')
    setExpiration('')
    setComment('')
    setValueError('')
    setExpirationError('')
    setFetchingError('')
  }

  return (
    <form
      style={{
        maxHeight: open === 'discountForm' ? '500px' : '0',
        marginBottom: open === 'discountForm' ? '3rem' : '0',
      }}
      className={styles.form}
      onSubmit={handleSubmit}
    >
      <label htmlFor="value" className={styles.label}>
        Värde
      </label>
      <fieldset className={styles.valueInput}>
        <input
          type="number"
          id="value"
          value={value}
          placeholder="Ange värdet"
          onChange={(e) => setValue(e.target.value)}
        />
        <ToggleTwoValuesInput
          valueOne="%"
          valueTwo="kr"
          setValue={setValueType}
        />
      </fieldset>
      <ErrorMessage message={valueError} />
      <label htmlFor="expiration" className={styles.label}>
        Utgår
      </label>
      <input
        type="date"
        id="expiration"
        value={expiration}
        min={today}
        onChange={(e) => setExpiration(e.target.value)}
      />
      <ErrorMessage message={expirationError} />
      <label htmlFor="comment" className={styles.label}>
        Kommnetar
      </label>
      <input
        type="text"
        id="comment"
        value={comment}
        placeholder="Kort kommnetar"
        maxLength="23"
        onChange={(e) => setComment(e.target.value)}
      />
      <ErrorMessage message={fetchingError} />
      <div className={styles.buttons}>
        <input
          type="button"
          value="Avbryt"
          className="transparentGreyBtn"
          onClick={() => openedForm('')}
        />
        <CustomButton
          text="Addera"
          theme="golden"
          pending={loading}
          onClick={handleSubmit}
        />
      </div>
    </form>
  )
}

export default AddDiscountForm
