import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useFlashMessages } from '../../contexts/FlashMessagesContext'
import { useNavigate } from 'react-router-dom'
import styles from './sendSmsForm.module.scss'
import ErrorMessage from '../error-message/ErrorMessage'
import CustomButton from '../custom-button/CustomButton'

function SendSmsForm() {
  // States.
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [fetchingError, setFetchingError] = useState(null)

  // User token.
  let { getIdToken } = useAuth()
  // Use flash messages.
  const { setMessage: setflashMsg } = useFlashMessages()
  // Browser navigation.
  let navigate = useNavigate()

  /**
   * Runs when the form is submitted.
   * @param {object} event - Submission event.
   */
  const handleSubmit = (event) => {
    event.preventDefault()
    if (!validate()) return
    sendSms()
  }

  /**
   * Submits an sms to be sent.
   */
  const sendSms = async () => {
    setLoading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_LINK}sms/broadcast`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await getIdToken()}`,
          },
          body: JSON.stringify({ message }),
        }
      )
      setLoading(false)

      // Exit if the submission is not successful.
      if (!response.ok) {
        throw new Error()
      }

      const feedback = await response.json()
      console.log(feedback)

      // Reset fields values.
      setMessage('')
      // Infom user through flash messages
      setflashMsg(
        `${feedback.usage?.countries?.se} Sms har skickats, kostnaden blir ${feedback.usage?.total_cost} DDK`
      )
      // Redirect to home page.
      navigate('/')
    } catch (error) {
      setLoading(false)
      setFetchingError('Något gick fel, vänligen försök igen senare')
    }
  }

  /**
   * Validates user input.
   * @returns {boolean} - Validation results.
   */
  const validate = () => {
    setMessageError(null)
    setFetchingError(null)

    if (!message) {
      setMessageError('Fältet kan inte vara tömt')
      return false
    }
    return true
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <label htmlFor="recipients" className={styles.label}>
        Mottagare
      </label>
      <input type="text" id="recipient" value={'Alla kunder'} disabled />
      <label htmlFor="message" className={styles.label}>
        Meddelandet
      </label>
      <textarea
        id="message"
        value={message}
        maxLength="600"
        onChange={(e) => setMessage(e.target.value)}
      />
      <ErrorMessage message={messageError} />
      <ErrorMessage message={fetchingError} />

      <CustomButton
        text="Skicka"
        theme="golden"
        pending={loading}
        onClick={handleSubmit}
      />
    </form>
  )
}

export default SendSmsForm
