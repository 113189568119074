import styles from './spinner.module.scss'
import React from 'react'
import PropTypes from 'prop-types'

function Spinner({color, dimension}) {
    return (
        <div style={{width: dimension, height: dimension}} className={styles.container}>
            <span style={{borderTop: `2px solid ${color}`}} className={styles.spinner}/>
        </div>
    )
}

Spinner.propTypes = {
    color: PropTypes.string,
    dimension: PropTypes.string
}

Spinner.defaultProps = {
    color: '#333333',
    dimension: '3rem'
}

export default Spinner
