import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useAuth } from '../../contexts/AuthContext'
import CustomButton from '../custom-button/CustomButton'
import CustomSelect from '../custom-select/CustomSelect'
import ErrorMessage from '../error-message/ErrorMessage'
import styles from './addVisitform.module.scss'

function AddVisitForm({ open, openedForm, addNewVistToList }) {
  // States.
  const [service, setService] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  // Client Id.
  const { id } = useParams()
  // User token.
  let { getIdToken } = useAuth()

  /**
   * Runs when the form is submitted.
   * @param {object} event - Submission event.
   */
  const handleSubmit = (event) => {
    event.preventDefault()
    if (!validate()) return
    addNewVisit(service)
  }

  /**
   * Adds a new visit.
   * @param {string} service - Provided service at the visit.
   */
  const addNewVisit = async (service) => {
    setLoading(true)

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_LINK}visits/?clientid=${id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await getIdToken()}`,
          },
          body: JSON.stringify({ service }),
        }
      )

      setLoading(false)

      if (!response.ok) {
        throw new Error()
      }

      const visit = await response.json()
      addNewVistToList(visit)

      // Rest fied value.
      setService('')
      // Close the form.
      openedForm('')
    } catch (error) {
      setError('Något gick fel, vänligen försök igen senare')
    }
  }

  /**
   * Validates user input.
   * @returns {boolean} - Validation results.
   */
  const validate = () => {
    setError('')
    if (!service) {
      setError('Ange tjänsten')
      return false
    }
    return true
  }

  return (
    <form
      style={{
        maxHeight: open === 'visitForm' ? 'unset' : '0',
        marginBottom: open === 'visitForm' ? '3rem' : '0',
      }}
      className={styles.visitForm}
      onSubmit={handleSubmit}
    >
      <label htmlFor="service" className={styles.label}>
        Tjänst
      </label>
      <CustomSelect setValue={setService} />
      <ErrorMessage message={error} />
      <div className={styles.buttons}>
        <input
          type="button"
          value="Avbryt"
          className="transparentGreyBtn"
          onClick={() => openedForm('')}
        />
        <CustomButton
          text="lägg till"
          theme="golden"
          pending={loading}
          onClick={handleSubmit}
        />
      </div>
    </form>
  )
}

export default AddVisitForm
