import { useState } from 'react'
import useQueue from '../../custom-hooks/useQueue'
import CustomButton from '../custom-button/CustomButton'
import ErrorMessage from '../error-message/ErrorMessage'
import { ReactComponent as Arrow } from './images/arrow.svg'
import styles from './addNonMemberToQueue.module.scss'

const AddNonMemberToQueue = ({ rest, success, transition, className }) => {
  // States.
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  // Use queue.
  const { addToQueue } = useQueue()

  /**
   * Runs when the form is submitted.
   * @param {object} event - Submission event.
   */
  const handleSubmit = async (event) => {
    event.preventDefault()
    // Reset error.
    setError('')
    // Validate user input.
    if (!name) return setError('Ange namn')
    // Add client (non member) to the waiting list.
    try {
      setLoading(true)
      await addToQueue({ name, id: 'NM' + Date.now() })
      setLoading(false)
    } catch (error) {
      setError('Något gick fel, vänligen försök igen senare')
      setLoading(false)
    }
    // Reset input and go to the next stage in the process.
    setName('')
    success()
  }

  return (
    <form
      style={{ animationName: transition }}
      className={`${className} ${styles.container}`}
      autoComplete="off"
    >
      <label htmlFor="name">Ditt namn</label>
      <input
        type="text"
        id="name"
        value={name}
        maxLength="23"
        onChange={(e) => setName(e.target.value)}
      />
      <ErrorMessage message={error} />
      <div className={styles.buttons}>
        <button className="greyBtn" onClick={rest} type="button">
          <Arrow className={styles.flip} />
        </button>
        <CustomButton
          text="Ställ dig i kön"
          theme="golden"
          pending={loading}
          onClick={handleSubmit}
        />
      </div>
    </form>
  )
}

export default AddNonMemberToQueue
