import React from 'react'
import { Link } from 'react-router-dom'
import OffersList from '../../components/offers-list/OffersList'
import styles from './offersPage.module.scss'

function OffersPage() {
  return (
    <div className="container">
      <div className="headerSpace"></div>
      <h1>Våra erbjudanden</h1>
      <OffersList />
      <Link to="add/" className={'blackBtn ' + styles.btn}>
        Lägg till ett nytt
      </Link>
    </div>
  )
}

export default OffersPage
