import React from "react"
import { useFlashMessages } from "../../contexts/FlashMessagesContext"
import PropTypes from "prop-types"
import styles from "./flashMessage.module.scss"

function FlashMessage() {
  const { message, mode } = useFlashMessages()
  if (!message) return null
  return <span className={`${styles.message} ${styles[mode]}`}>{message}</span>
}

FlashMessage.propTypes = {
  messgae: PropTypes.string,
  mode: PropTypes.oneOf(["sucsess", "attention", "error"]),
}

FlashMessage.defaultProps = {
  messgae: "",
  mode: "sucsess",
}

export default FlashMessage
