import React, { useContext, useState } from 'react'

const FlashMessagesContext = React.createContext('')

export function useFlashMessages() {
  return useContext(FlashMessagesContext)
}

export function FlashMessagesProvider({ children }) {
  // State declaration.
  const [message, setMessage] = useState('')
  const [mode, setMode] = useState('sucsess')

  /**
   * The value provided by this context.
   */
  const value = {
    message,
    mode,
    setMessage,
    setMode,
  }

  return (
    <FlashMessagesContext.Provider value={value}>
      {children}
    </FlashMessagesContext.Provider>
  )
}
