import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import logo from './images/logo.svg'
import menuBtnIcon from './images/menuBtnIcon.svg'
import menuBtnCloseIcon from './images/menuBtnCloseIcon.svg'
import styles from './menuBar.module.scss'

function MenuBar() {
  // State that determine if the menu is open or closed.
  const [menuOpen, setMenuOpen] = useState(false)
  // Use auth methods.
  let { currentUser, signOut } = useAuth()
  // Hide menu bar in the queue view.
  const location = useLocation()
  const queueView = ['/queue/register', '/queue/show'].includes(
    location.pathname
  )
  if (queueView) return null

  return (
    <div className={styles.bar}>
      <nav className={styles.container}>
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={logo} alt="logo" />
          <button
            className={styles.menuBtn}
            onClick={() => {
              setMenuOpen(!menuOpen)
            }}
          >
            {menuOpen ? (
              <img src={menuBtnCloseIcon} alt="Menu close" />
            ) : (
              <img src={menuBtnIcon} alt="Menu" />
            )}
          </button>
        </div>
        <ul
          className={menuOpen ? styles.menu : `${styles.menu} ${styles.closed}`}
          onClick={() => {
            setMenuOpen(false)
          }}
        >
          {currentUser ? (
            <>
              <li className={styles.menuItem}>
                <NavLink
                  to="/search"
                  className={(navData) =>
                    navData.isActive ? styles.selected : ''
                  }
                >
                  Kundsökning
                </NavLink>
              </li>
              <li className={styles.menuItem}>
                <NavLink
                  to="/queue/dashboard"
                  className={(navData) =>
                    navData.isActive ? styles.selected : ''
                  }
                >
                  Kö
                </NavLink>
              </li>
              <li className={styles.menuItem}>
                <NavLink
                  to="/queue/register"
                  className={(navData) =>
                    navData.isActive ? styles.selected : ''
                  }
                >
                  Köregistrering
                </NavLink>
              </li>
              <li className={styles.menuItem}>
                <NavLink
                  to="/clients/add"
                  className={(navData) =>
                    navData.isActive ? styles.selected : ''
                  }
                >
                  Ny kund
                </NavLink>
              </li>
              <li className={styles.menuItem}>
                <NavLink
                  to="/offers/"
                  className={(navData) =>
                    navData.isActive ? styles.selected : ''
                  }
                >
                  Erbjudanden
                </NavLink>
              </li>
              <li className={styles.menuItem}>
                <NavLink
                  to="/sms/send"
                  className={(navData) =>
                    navData.isActive ? styles.selected : ''
                  }
                >
                  Skicka SMS
                </NavLink>
              </li>
              <li className={`${styles.menuItem} ${styles.clientInfo}`}>
                <div>
                  <span className={styles.clientName}>
                    {currentUser.displayName}
                  </span>
                  <button onClick={signOut}>Logga ut</button>
                </div>
              </li>
            </>
          ) : (
            <li className={`${styles.menuItem} ${styles.clientInfo}`}>
              <NavLink
                to="/auth/signin"
                className={(navData) =>
                  navData.isActive ? styles.selected : ''
                }
              >
                Logga in
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  )
}

export default MenuBar
