import React from 'react'
import styles from './errorMessage.module.scss'

function ErrorMessage({message}) {
  return (
  message? <span className={styles.errorMsg}>{message}</span>: null
  ) 
}

export default ErrorMessage
