import styles from './addToQueuePage.module.scss'
import QueueCounter from '../../components/queue-counter/QueueCounter'
import AddToQueueSlides from '../../components/add-to-queue-slides/AddToQueueSlides'

const AddToQueuePage = () => {
  return (
    <div className={styles.pageDivider}>
      <QueueCounter className={styles.queue} />
      <AddToQueueSlides className={styles.regContainer} />
    </div>
  )
}

export default AddToQueuePage
