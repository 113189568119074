import { React, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './addClientForm.module.scss'
import { useAuth } from '../../contexts/AuthContext'
import CustomButton from '../custom-button/CustomButton'
import ErrorMessage from '../error-message/ErrorMessage'

function AddClientForm() {
  // Field vaule states.
  const [firstName, setFirstName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')

  // loading and errors states.
  const [loading, setLoading] = useState(false)
  const [fetchingError, setFetchingError] = useState(null)
  const [nameError, setNameError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [mobileError, setMobileError] = useState(null)

  // User token.
  let { getIdToken } = useAuth()
  // Browsing navigation.
  let navigate = useNavigate()

  /**
   * Runs when the form is submitted.
   * @param {object} event - Submission event.
   */
  const handleSubmit = (event) => {
    event.preventDefault()
    if (!validate()) return
    addClient()
  }

  /**
   * Adds a new client.
   */
  const addClient = async () => {
    try {
      setLoading(true)

      const response = await fetch(`${process.env.REACT_APP_API_LINK}clients`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await getIdToken()}`,
        },
        body: JSON.stringify({
          firstName,
          surname,
          email,
          mobile,
        }),
      })

      setLoading(false)

      if (!response.ok) {
        throw new Error()
      }

      const client = await response.json()
      navigate(`/clients/${client.id}`)
      restValues()
    } catch (error) {
      setFetchingError('Något gick fel. Vänligen försök igen senare')
    }
  }

  /**
   * Validates user input.
   * @returns {boolean} - Validation results.
   */
  const validate = () => {
    let validInput = true
    resetErrors()
    if (!firstName) {
      setNameError('Ange name')
      validInput = false
    }
    const mobileRegex = new RegExp('^[0-9]{10}$')
    if (mobile && !mobileRegex.test(mobile)) {
      setMobileError('Mobilnummret är inte gälltigt')
      validInput = false
    }
    const emailRegex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    if (email && !emailRegex.test(email)) {
      setEmailError('E-post är inte gälltigt')
      validInput = false
    }
    if (!mobile && !email) {
      setEmailError('Ange e-post')
      setMobileError('Ange mobilnummr')
      validInput = false
    }
    return validInput
  }

  /**
   * Resets fields values.
   */
  const restValues = () => {
    setFirstName('')
    setSurname('')
    setMobile('')
    setEmail('')
  }

  /**
   * Resets all Errors.
   */
  const resetErrors = () => {
    setFetchingError('')
    setNameError('')
    setEmailError('')
    setMobileError('')
  }

  return (
    <div className={styles.container}>
      <h1>En ny kund</h1>
      <form onSubmit={handleSubmit} className={styles.AddClientForm}>
        <fieldset className={styles.nameInput}>
          <label htmlFor="name" className={styles.label}>
            Nämn
          </label>
          <input
            type="text"
            id="name"
            value={firstName}
            placeholder="Förnamn"
            maxLength="30"
            className={styles.firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <input
            type="text"
            id="name"
            value={surname}
            placeholder="Efternamn"
            maxLength="30"
            className={styles.surName}
            onChange={(e) => setSurname(e.target.value)}
          />
          <ErrorMessage message={nameError} />
        </fieldset>
        <label htmlFor="email" className={styles.label}>
          email
        </label>
        <input
          type="email"
          id="email"
          value={email}
          placeholder="ex. jan@gmail.com"
          onChange={(e) => setEmail(e.target.value)}
        />
        <ErrorMessage message={emailError} />
        <label htmlFor="mobile" className={styles.label}>
          Mobilnummer
        </label>
        <input
          type="number"
          className={styles.mob}
          id="mobile"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        />
        <ErrorMessage message={mobileError} />
        <ErrorMessage message={fetchingError} />

        <CustomButton
          text="Addera"
          theme="golden"
          pending={loading}
          onClick={handleSubmit}
        />
      </form>
    </div>
  )
}

export default AddClientForm
