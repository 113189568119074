import styles from './visitsCounter.module.scss'

const VisitsCounter = ({ statistics }) => {
  return (
    <div className={styles.counters}>
      <div className={styles.counter}>
        <h2 className={styles.number}>{statistics.total}</h2>
        <span className={styles.title}>Kunder idag</span>
      </div>
      <div className={styles.counter}>
        <h2 className={styles.number}>{statistics.members}</h2>
        <span className={styles.title}>Medlemmar</span>
      </div>
      <div className={styles.counter}>
        <h2 className={styles.number}>{statistics.nonMembers}</h2>
        <span className={styles.title}>Icke medlemmar</span>
      </div>
    </div>
  )
}

export default VisitsCounter
