import React from 'react'
import { useParams } from 'react-router'
import { useAuth } from '../../contexts/AuthContext'
import { ReactComponent as ClientAvatar } from './images/client-icon.svg'
import DotsDropdownMenu from '../dots-dropdown-menu/DotsDropdownMenu'
import styles from './clientHeader.module.scss' 
import EditableText from '../editable-text/EditableText'
import EditableNameLable from '../editable-name-lable/EditableNameLable'


const ClientHeader = ({firstName, surname, registrationDay, mobile, email, openedForm, onClientUpdate}) => {

  // Client Id.
  const { id } = useParams()  
  // User token.
  let {getIdToken} = useAuth()

  // Updating function.
  const updateMobile = (newMobile) => {
    uppdateClient({mobile:newMobile})
  }
  const updateEmail= (newEmail) => {
    uppdateClient({email:newEmail})
  }
  const updateName= (newName) => {
    uppdateClient(newName)
  }

  const uppdateClient = async (updatedFields) => {
    try {
      const response = await fetch (`${process.env.REACT_APP_API_LINK}clients/${id}`,
        {
          method:'PATCH',
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await getIdToken()}`
          },
          body: JSON.stringify(updatedFields)
        }
      )
      const updatedClient = (await response.json()) 
      if(!response.ok) { return }
      onClientUpdate(updatedClient)
    } catch (error) {
      // To be handled.
    }

  }

  return (
    <>
    <div className={styles.header}>
      <ClientAvatar className={styles.clientAvatar}/>
      <EditableNameLable firstName={firstName} surname={surname} setNewName={updateName}/>
      <DotsDropdownMenu openedForm={openedForm}/>
    </div>
    <div className={styles.inlineInfo}>
      <EditableText lable='Mobilnummer' text={mobile} setNewText={updateMobile}/>
      <EditableText lable='Epost' text={email} setNewText={updateEmail}/>
      <div>
        <span className={styles.infoLable}>En kund sedan </span>
        <span className={styles.infoText}>{registrationDay}</span>
      </div>
    </div>
    </>
  )
}

export default ClientHeader
