import React from 'react'
import AddClientForm from '../../components/add-client-form/AddClientForm'

function NewClientPage() {
  return (
    <div className='container'>
      <div className='headerSpace'></div>
      <AddClientForm/>
    </div>
  )
}

export default NewClientPage
