import React from "react"
import SendSmsForm from "../../components/send-sms-form/SendSmsForm"

function SendSmsPage() {
  return (
    <div className="container">
      <div className="headerSpace"></div>
      <h1>Skicka ett SMS</h1>
      <SendSmsForm />
    </div>
  )
}

export default SendSmsPage
