import PropTypes from 'prop-types'
import ToClientProfileBtn from '../to-client-profile-btn/ToClientProfileBtn'
import { ReactComponent as ServiceIcon } from './images/serviceIcon.svg'
import styles from './queueDashboardHeader.module.scss'

const QueueDashboardHeader = ({ serviceList, next }) => {
  return (
    <div className={styles.atService}>
      <ServiceIcon className={styles.icon} />
      <div className={styles.serviceList}>
        <span className={styles.title}>
          {serviceList?.length ? 'Betjänas just nu' : 'Ingen betjänas just nu'}
        </span>
        {serviceList?.map((client) => (
          <ToClientProfileBtn client={client} key={client.id} />
        ))}
      </div>
      <button className={styles.nextBtn} onClick={next}>
        Nästa
      </button>
    </div>
  )
}

QueueDashboardHeader.prototype = {
  serviceList: PropTypes.array,
  next: PropTypes.func,
}

QueueDashboardHeader.defaultProps = {
  serviceList: [],
}

export default QueueDashboardHeader
