import React from 'react'
import { useParams } from 'react-router'
import { useState, useEffect } from 'react'
import ClientHeader from '../../components/client-header/ClientHeader'
import ClientVisitsList from '../../components/client-visits-list/ClientVisitsList'
import DiscountList from '../../components/discount-list/DiscountList'
import AddVisitForm from '../../components/add-visit-form/AddVisitForm'
import { useAuth } from '../../contexts/AuthContext'
import { useFlashMessages } from '../../contexts/FlashMessagesContext'
import AddDiscountForm from '../../components/add-discount-form/AddDiscountForm'
import FlashMessage from '../../components/flash-message/FlashMessage'

const ClientViewPage = () => {
  // State declaration.
  const [client, setClient] = useState('')
  const [openedForm, setOpenedForm] = useState('')
  const [discounts, setDiscounts] = useState([])
  const [visits, setVisits] = useState([])

  // Get client Id.
  const { id } = useParams()
  // Get user token.
  let { getIdToken, currentUser, authLoading } = useAuth()
  // Flash messages.
  const { setMessage, setMode } = useFlashMessages()

  /**
   * Get client data and update state accordingly.
   */
  const getClient = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_LINK}clients/${id}?fullCopy=true`,
        {
          headers: { Authorization: `Bearer ${await getIdToken()}` },
        }
      )
      const client = await response.json()
      setClient(client)
      setVisits(client.visits)
    } catch (error) {
      setMode('error')
      setMessage('Något gick fel, vänligen försök igen senare')
    }
  }

  /**
   * Get client discounts and update state accordingly.
   */
  const getDiscounts = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_LINK}discounts/?clientid=${id}`,
      {
        headers: { Authorization: `Bearer ${await getIdToken()}` },
      }
    )
    const discounts = await response.json()
    if (!response.ok) {
      return
    }
    setDiscounts(discounts)
  }

  // When the page is loaded or when the user token is updated,
  // update the content of the page.
  useEffect(() => {
    // Exit if the authentication is still in state of loading.
    if (authLoading) return
    getClient()
    getDiscounts()
  }, [currentUser])

  /**
   * Add new visit to the visit list and trigger discounts list update.
   * (For every new visit a new discount is automatically added)
   * @param {object} visit - Visit data.
   */
  const addNewVistToList = (visit) => {
    setVisits([...visits, visit])
    getDiscounts()
  }

  /**
   * Add a discount to the discount list.
   * @param {object} newDiscount - Discount data.
   */
  const addDiscountToList = async (newDiscount) => {
    setDiscounts([...discounts, newDiscount])
  }

  /**
   * Update the state of a discount to be activated.
   * @param {string} discountId - Activated discount ID.
   */
  const updateActivatedDiscount = (discountId) => {
    setDiscounts(
      discounts.map((discount) =>
        discount._id === discountId
          ? { ...discount, state: 'activated' }
          : discount
      )
    )
  }

  return (
    <div className="container">
      <div className="headerSpace"></div>
      <ClientHeader
        registrationDay={client.createdAt?.slice(0, 10)}
        {...client}
        openedForm={setOpenedForm}
        onClientUpdate={setClient}
      />
      <AddVisitForm
        open={openedForm}
        openedForm={setOpenedForm}
        addNewVistToList={addNewVistToList}
      />
      <AddDiscountForm
        open={openedForm}
        openedForm={setOpenedForm}
        addDiscountToList={addDiscountToList}
        clinetId={id}
      />
      <DiscountList
        discounts={discounts}
        onActivate={updateActivatedDiscount}
      />
      <ClientVisitsList visits={visits} />
      <FlashMessage message="" />
    </div>
  )
}

export default ClientViewPage
