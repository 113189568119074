import useQueue from '../../custom-hooks/useQueue'
import personIcon from './images/personIcon.svg'
import styles from './queueCounter.module.scss'

const QueueCounter = ({ className }) => {
  const { waitingListLength } = useQueue()
  if (waitingListLength === 0) {
    return (
      <div className={`${className} ${styles.container}`}>
        <h1>Välkommen</h1>
        <p className={styles.info}>
          Du är först i kö! vänligen registera din information.
        </p>
      </div>
    )
  }
  return (
    <div className={`${className} ${styles.container}`}>
      <div className={styles.imageContainer}>
        <img src={personIcon} alt="person icon" />
        <span className={styles.clientsNum}>{waitingListLength}X</span>
      </div>
      <p className={styles.info}>Det är {waitingListLength} i kö just nu.</p>
    </div>
  )
}

export default QueueCounter
