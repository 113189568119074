import React from "react"
import EditOfferForm from "../../components/edit-offer-form/EditOfferForm"

function EditOfferPage() {
  return (
    <div className="container">
      <div className="headerSpace"></div>
      <h1>Ett nytt erbjudande</h1>
      <EditOfferForm></EditOfferForm>
    </div>
  )
}

export default EditOfferPage
