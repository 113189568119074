import React from 'react'
import { Link } from 'react-router-dom'
import styles from './clientList.module.scss' 

const ClientList = ({clients}) => {
	return (
		<ul className={styles.results}>
			{clients?.map( client => (
				<li key={client.id}>
					<Link to={`/clients/${client.id}`}>
					{`${client.firstName} ${client.surname? client.surname: ''}`}
					</Link>
					<div className={styles.clientInfo}>
						<span>Kund sedan {client.createdAt?.slice(0,10)}</span>
					</div>
				</li>
			))}
		</ul>
	)
}

export default ClientList
