import { useEffect } from 'react'
import WaitingList from '../../components/wating-list/WaitingList'
import useQueue from '../../custom-hooks/useQueue'
import { ReactComponent as ServiceIcon } from './images/serviceIcon.svg'
import styles from './waitingListPage.module.scss'

const WaitingListPage = () => {
  // Use waiting list data.
  const { waitingList, serviceList, serviceListLength } = useQueue()

  /**
   * Play notification.
   * @param {string} clientName - The name of the client to call.
   */
  const playNotification = async (clientName = '') => {
    const audioContext = new AudioContext()
    try {
      // Exit if audio is suspended by the browser.
      if (audioContext.state !== 'running') return

      // Play notification sound.
      /* This audio file is downloaded from notificationsounds.com */
      let notification = new Audio('/sounds/notification.mp3')
      notification.currentTime = 0
      await notification.play()

      // Call the client by his name.
      // New utterance object.
      const utterance = new SpeechSynthesisUtterance(
        `Nästa kund ${clientName}. Varsågod`
      )
      utterance.lang = 'sv'
      utterance.volume = 1
      // Speak the name of the client when the beep is finished.
      notification.onended = () => speechSynthesis.speak(utterance)
    } catch (error) {
      console.error(error)
    }
  }

  // Play notification when service list has been uppdated.
  useEffect(() => {
    if (serviceListLength === 0) return
    // Get the newly add client to the service list.
    const client = serviceList[serviceListLength - 1]
    playNotification(client.name)
    console.log(serviceList)
  }, [serviceList])

  return (
    <main className={styles.container}>
      <div className={styles.atService}>
        <ServiceIcon className={styles.icon} />
        <div className={styles.waitingList}>
          <span className={styles.title}>
            {serviceListLength ? 'Betjänas just nu' : 'Ingen betjänas just nu'}
          </span>
          {serviceList?.map((client) => (
            <h2 className={styles.name} key={client.id}>
              {client.name}
            </h2>
          ))}
        </div>
      </div>
      <WaitingList waitingList={waitingList} bigScreen />
    </main>
  )
}

export default WaitingListPage
