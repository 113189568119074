import React, { useState } from 'react'
import styles from './editableNameLable.module.scss'
import editIcon from './images/edit-icon.svg'
import okIcon from './images/ok-icon.svg'
import cancelIcon from './images/cancel-icon.svg'

function EditableNameLable({ firstName, surname, setNewName }) {
  // State declaration.
  const [editing, setEditing] = useState(false)
  const [editedFirstName, setEditedFirstName] = useState('')
  const [editedSurname, setEditedSurname] = useState('')

  /**
   * Runs when the form is submitted.
   * @param {object} event - Submission event.
   */
  const handleSubmit = (event) => {
    event.preventDefault()
    setNewName({
      firstName: editedFirstName,
      surname: editedSurname,
    })
    setEditing(false)
  }

  /**
   * Activates editing mode.
   */
  const activateEditing = () => {
    setEditedFirstName(firstName)
    setEditedSurname(surname)
    setEditing(true)
  }

  // If editing mode is active, return editing form.
  if (editing) {
    return (
      <form className={styles.name} onSubmit={handleSubmit}>
        <input
          type="text"
          value={editedFirstName}
          maxLength="30"
          onChange={(e) => setEditedFirstName(e.target.value)}
          className={styles.text}
        />
        <input
          type="text"
          value={editedSurname}
          maxLength="30"
          onChange={(e) => setEditedSurname(e.target.value)}
          className={styles.text}
        />
        <button className={styles.cancelBtn} onClick={() => setEditing(false)}>
          <img src={cancelIcon} alt="cancel icon" />
        </button>
        <button type="submit" onClick={handleSubmit} className={styles.okBtn}>
          <img src={okIcon} alt="ok icon" />
        </button>
      </form>
    )
  }

  return (
    <div className={styles.name}>
      <h1>{`${firstName ? firstName : ''} ${surname ? surname : ''}`}</h1>
      <button className={styles.editBtn} onClick={activateEditing}>
        <img src={editIcon} alt="edit icon" />
      </button>
    </div>
  )
}

export default EditableNameLable
