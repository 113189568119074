import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './controlOfferCard.module.scss'
import { useAuth } from '../../contexts/AuthContext'
import { ReactComponent as DeleteIcon } from './images/deleteIcon.svg'
import { ReactComponent as PauseIcon } from './images/pauseIcon.svg'
import { ReactComponent as PlayIcon } from './images/playIcon.svg'
import { ReactComponent as EditIcon } from './images/editIcon.svg'
import Spinner from '../spinner/Spinner'

function OfferCard({
  expiration,
  title,
  description,
  imageUrl,
  state,
  _id,
  removeOfferfromList,
  updateOfferState,
}) {
  // States.
  const [loadingStateChange, setLoadingStateChange] = useState(false)

  // Browser navigation
  let navigate = useNavigate()

  // User token.
  let { getIdToken } = useAuth()

  const handleDeletion = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_LINK}offers/${_id}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${await getIdToken()}`,
          },
        }
      )
      if (response.ok) {
        removeOfferfromList(_id)
      }
    } catch (error) {
      // To be handled
      console.log(error.message)
    }
  }

  const handleChangeState = async () => {
    try {
      setLoadingStateChange(true)
      const response = await fetch(
        `${process.env.REACT_APP_API_LINK}offers/${_id}?state_event=${
          state === 'active' ? 'deactivate' : 'activate'
        }`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${await getIdToken()}`,
          },
        }
      )
      setLoadingStateChange(false)
      if (!response.ok) {
        return
      }
      const updatedOffer = await response.json()
      console.log(updatedOffer, _id)
      updateOfferState(_id, updatedOffer.state)
    } catch (error) {
      // To be handled
      setLoadingStateChange(false)
      console.log(error.message)
    }
  }

  return (
    <div
      className={
        state === 'active'
          ? styles.container
          : `${styles.container} ${styles.inactive}`
      }
    >
      <div className={styles.controller}>
        <button className={styles.btn} onClick={handleChangeState}>
          {loadingStateChange ? (
            <Spinner dimension="1.2rem" color="#ffffff" />
          ) : state === 'active' ? (
            <PauseIcon />
          ) : (
            <PlayIcon />
          )}
        </button>
        <button
          className={styles.btn}
          onClick={() => {
            navigate(`/offers/edit/${_id}`)
          }}
        >
          <EditIcon />
        </button>
        <button className={styles.btn} onClick={handleDeletion}>
          <DeleteIcon />
        </button>
      </div>
      <div
        style={{ backgroundImage: `url(${imageUrl})` }}
        className={styles.imageContainer}
      >
        {expiration ? (
          <span className={styles.exp}>
            t.o.m {expiration?.substring(0, 10)}
          </span>
        ) : null}
      </div>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  )
}

export default OfferCard
