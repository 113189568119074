import { ReactComponent as Arrow } from './images/arrow.svg'
import { Link } from 'react-router-dom'
import styles from './toClientProfileBtn.module.scss'

const ToClientProfileBtn = ({ client }) => {
  if (client.profileUrl) {
    return (
      <Link className={styles.nameBtn} key={client.id} to={client.profileUrl}>
        {client.name} <Arrow />
      </Link>
    )
  }
  return (
    <h2 className={styles.name} key={client.id}>
      {client.name}
    </h2>
  )
}

export default ToClientProfileBtn
