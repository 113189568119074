import { useState } from 'react'
import useQueue from '../../custom-hooks/useQueue'
import { ReactComponent as Arrow } from './images/arrow.svg'
import AddMemberToQueue from '../add-member-to-queue/AddMemberToQueue'
import AddNonMemberToQueue from '../add-non-member-to-queue/AddNonMemberToQueue'
import styles from './addToQueueSlides.module.scss'

const AddToQueueSlides = ({ className }) => {
  // States for current stage and transtion.
  const [stage, setStage] = useState('ask if member')
  const [transition, setTransition] = useState('fadeinright')

  // Get waiting list status.
  const { waitingListLength, serviceCapacity } = useQueue()

  // Go to different stages.
  const rest = () => {
    setTransition('fadeinleft')
    setStage('ask if member')
  }
  const goToSuccess = () => {
    setTransition('fadeinright')
    setStage('success')
    setTimeout(() => {
      setStage('ask if member')
    }, 3000)
  }
  const goToNonMemberStage = () => {
    setTransition('fadeinright')
    setStage('not member')
  }
  const goToMemberStage = () => {
    setTransition('fadeinright')
    setStage('member')
  }

  if (stage === 'ask if member') {
    return (
      <div
        style={{ animationName: transition }}
        className={`${className} ${styles.container}`}
      >
        <h1>Ställ dig i kön</h1>
        <button className={styles.wideBtn} onClick={goToMemberStage}>
          Medlem <Arrow />
        </button>
        <button
          className={`greyBtn ${styles.wideBtn}`}
          onClick={goToNonMemberStage}
        >
          Icke medlem <Arrow />
        </button>
      </div>
    )
  }

  if (stage === 'not member') {
    return (
      <AddNonMemberToQueue
        rest={rest}
        success={goToSuccess}
        transition={transition}
        className={className}
      />
    )
  }

  if (stage === 'member') {
    return (
      <AddMemberToQueue
        rest={rest}
        success={goToSuccess}
        transition={transition}
        className={className}
      />
    )
  }

  if (stage === 'success') {
    return (
      <div
        style={{ animationName: transition }}
        className={`${className} ${styles.container}`}
      >
        <h1>Tack!</h1>
        <p>
          {waitingListLength < serviceCapacity
            ? 'Du är nu först i kö! Det blir snart din tur'
            : 'Du står nu i kö! Ta en kopp kaffe och vänta på din tur :)'}
        </p>
      </div>
    )
  }
}

export default AddToQueueSlides
