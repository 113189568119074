import React from 'react'
import ClientList from '../client-list/ClientList'
import ErrorMessage from '../error-message/ErrorMessage'
import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import styles from './clientSearch.module.scss'
import CustomButton from '../custom-button/CustomButton'

const ClientSearch = () => {
  // State declaration.
  const [searchText, setSearchText] = useState('')
  const [clients, setClients] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [feedback, setFeedback] = useState('')

  // User token.
  let { getIdToken } = useAuth()

  /**
   * Runs when the form is submitted.
   * @param {object} event - Submission event.
   */
  const handleSubmit = (event) => {
    event.preventDefault()
    setFeedback('')
    if (!validate()) return
    setClients([])
    getClients()
    setSearchText('')
  }

  /**
   * Gets glients who matches the search phrase.
   */
  const getClients = async () => {
    try {
      setLoading(true)
      const response = await fetch(
        process.env.REACT_APP_API_LINK + 'clients/search/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await getIdToken()}`,
          },
          body: JSON.stringify({ searchPhrase: searchText }),
        }
      )
      setLoading(false)

      if (!response.ok) {
        throw new Error()
      }

      const clientsFound = await response.json()
      if (!clientsFound || clientsFound?.length === 0) {
        setFeedback('Ingen kunde hittades')
        return
      }

      setClients(clientsFound)
    } catch (error) {
      setError('Något gick fel, vänligen försök igen senare')
    }
  }

  /**
   * Validates user input.
   * @returns {boolean} - Validation results.
   */
  const validate = () => {
    setError(null)
    if (!searchText) {
      setError('Sökfältet kan inte vara tömt')
      return false
    }
    return true
  }

  return (
    <div className={styles.searchContainer}>
      <form className={styles.searchForm} onSubmit={handleSubmit}>
        <h1>Kundsökning</h1>
        <div className={styles.searchArea}>
          <input
            type="text"
            placeholder="Namn eller mobilnummer"
            value={searchText}
            className={styles.searchField}
            onChange={(event) => setSearchText(event.target.value)}
          />
          <CustomButton
            text="Sök"
            theme="golden"
            loading={loading}
            onClick={handleSubmit}
          />
        </div>
        <ErrorMessage message={error} />
      </form>
      {feedback ? <span className={styles.feedback}>{feedback}</span> : null}
      <ClientList clients={clients} />
    </div>
  )
}

export default ClientSearch
