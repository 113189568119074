import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import useQueue from '../../custom-hooks/useQueue'
import CustomButton from '../custom-button/CustomButton'
import ErrorMessage from '../error-message/ErrorMessage'
import Switch from '../switch/Switch'
import { ReactComponent as Arrow } from './images/arrow.svg'
import styles from './addMemberToQueue.module.scss'

const AddMemberToQueue = ({ rest, success, transition, className }) => {
  const [loading, setLoading] = useState(false)
  const [mobile, setMobile] = useState('')
  const [smsReminder, setSmsReminder] = useState(false)
  const [error, setError] = useState('')

  // Get user token.
  let { getIdToken } = useAuth()
  // Use queue.
  const { addToQueue, waitingListLength } = useQueue()

  /**
   * Runs when the form is submitted.
   * @param {object} event - Submission event.
   */
  const handleSubmit = async (event) => {
    event.preventDefault()
    // Reset error.
    setError('')
    // Validate user input.
    const mobileRegex = new RegExp('^[0-9]{10}$')
    if (mobile && !mobileRegex.test(mobile)) {
      setError('Mobilnummret är inte gälltigt')
      return
    }
    try {
      setLoading(true)
      // Get client data.
      const client = await getClientdata()
      if (!client) {
        setError('Detta nummer verker inte vara registerad hos oss')
        setLoading(false)
        return
      }
      // Add the client to the waiting list.
      await addToQueue({
        name: client.firstName,
        mobile,
        smsReminder,
        id: client.id,
        profileUrl: '/clients/' + client.id,
        isMember: true,
      })
      setLoading(false)
    } catch (error) {
      setError('Något gick fel, vänligen försök igen senare')
      setLoading(false)
    }
    // Reset input and go to the next stage in the process.
    setMobile('')
    success()
  }

  /**
   * Gets registered client data.
   * @returns {object | null} - Client data.
   */
  const getClientdata = async () => {
    // Get client data.
    const response = await fetch(
      process.env.REACT_APP_API_LINK + 'clients/search/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await getIdToken()}`,
        },
        body: JSON.stringify({ searchPhrase: mobile }),
      }
    )
    // Throw erorr in case of unsuccessful response.
    if (!response.ok) throw new Error()
    // If no clients has been found, return null.
    const clientsFound = await response.json()
    if (!clientsFound || clientsFound?.length === 0) {
      return null
    }
    // Retun client data.
    return clientsFound[0]
  }

  return (
    <form
      className={`${className} ${styles.container}`}
      style={{ animationName: transition }}
      autoComplete="off"
    >
      <label htmlFor="mobile">Ditt registrerade mobilnummer</label>
      <input
        type="number"
        id="mobile"
        value={mobile}
        placeholder="07XXXXXXXX"
        onChange={(e) => setMobile(e.target.value)}
      />
      <ErrorMessage message={error} />
      {waitingListLength ? (
        <Switch
          checked={smsReminder}
          label="Påminn mig med SMS"
          setChecked={setSmsReminder}
        />
      ) : null}
      <div className={styles.buttons}>
        <button className="greyBtn" onClick={rest} type="button">
          <Arrow className={styles.flip} />
        </button>
        <CustomButton
          text="Ställ dig i kön"
          theme="golden"
          pending={loading}
          onClick={handleSubmit}
        />
      </div>
    </form>
  )
}

export default AddMemberToQueue
