import QueueDashboardHeader from '../../components/queue-dashboard-header/QueueDashboardHeader'
import VisitsCounter from '../../components/visits-counter/VisitsCounter'
import WaitingList from '../../components/wating-list/WaitingList'
import useQueue from '../../custom-hooks/useQueue'
import styles from './queueDashboardPage.module.scss'
const QueueDashboardPage = () => {
  // Use waiting list data.
  const { waitingList, serviceList, statistics, next, removeFromQueue } =
    useQueue()
  return (
    <main className="container">
      <div className="headerSpace" />
      <QueueDashboardHeader serviceList={serviceList} next={next} />
      <div className={styles.verticalDivider}>
        <WaitingList
          waitingList={waitingList}
          removeFromQueue={removeFromQueue}
        />
        <VisitsCounter statistics={statistics} />
      </div>
    </main>
  )
}

export default QueueDashboardPage
