import React from "react"
import styles from "./fileUploader.module.scss"
import uploadedFileIcon from "./images/uploadedFileIcon.svg"

function FileUploader({ lable, state, progress, onFileChosen, acceptedTypes }) {
  // Render different UI depending in the state.
  if (state === "running") {
    return (
      <div className={styles.progressBar}>
        <span style={{ width: progress + "%" }} className={styles.progress}>
          {progress}%
        </span>
      </div>
    )
  }

  if (state === "uploaded") {
    return (
      <div className={styles.uploadGroup}>
        <img src={uploadedFileIcon} alt="File icon" />
        <label className={styles.pickerLable} htmlFor="custom-file-picker">
          Välj en ny fil
          <input
            id="custom-file-picker"
            className={styles.filePick}
            type="file"
            onChange={onFileChosen}
            accept={acceptedTypes}
          />
        </label>
      </div>
    )
  }

  return (
    <label className={styles.pickerLable} htmlfor="custom-file-picker">
      {lable}
      <input
        id="custom-file-picker"
        className={styles.filePick}
        type="file"
        onChange={onFileChosen}
        accept={acceptedTypes}
      />
    </label>
  )
}

export default FileUploader
