import PropTypes from 'prop-types'
import styles from './switch.module.scss'

const Switch = ({ checked, setChecked, label }) => {
  return (
    <>
      <input
        type="checkbox"
        id="toggle"
        className={styles.checkbox}
        checked={checked}
        onChange={() => {
          setChecked(!checked)
        }}
      />
      <label htmlFor="toggle" className={styles.switch}>
        {label}
      </label>
    </>
  )
}

Switch.propTypes = {
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  label: PropTypes.string,
}

Switch.defaultProps = {
  checked: false,
  label: '',
}
export default Switch
