import { useState, useEffect } from 'react'
import { useAuth } from '../contexts/AuthContext'
import {
  getDatabase,
  get,
  ref,
  set,
  push,
  onValue,
  query,
  limitToFirst,
  remove,
  child,
} from 'firebase/database'

export default function useQueue() {
  // State declaration.
  const [waitingList, setWaitingList] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [serviceCapacity, setServiceCapacity] = useState(1)
  const [memberStatistics, setMemberStatistics] = useState('0')
  const [nonMemberStatistics, setNonMemberStatistics] = useState('0')

  // Get user token.
  let { getIdToken } = useAuth()

  // Get instance of real time database.
  const realtimeDB = getDatabase()
  // Referance to waiting list in Växjö center document.
  const waitingListRef = ref(realtimeDB, 'vxo01/waitingList')
  // Referance to list of clients being served in Växjö.
  const serviceListRef = ref(realtimeDB, 'vxo01/serviceList')
  // Today's date.
  const todaysDate = new Date().toDateString().replaceAll(' ', '_')
  // Referance to statistics of visitors number in Växjö.
  const todaysStatisticsRef = ref(realtimeDB, 'vxo01/statistics/' + todaysDate)
  // Referance to statistics of visitors (members) in Växjö.
  const memberStatisticsRef = child(todaysStatisticsRef, 'member/')
  // Referance to statistics of visitors (non members) in Växjö.
  const nonMemberStatisticsRef = child(todaysStatisticsRef, 'nonMember/')

  /**
   * Adds client to the waiting queue.
   * @param {object} client - info about the waiting client.
   */
  const addToQueue = async (client) => {
    const newClientRef = push(waitingListRef)
    await set(newClientRef, client)
    await addVisitToStatistics(client)
  }

  /**
   * Adds one to todays visiting statistics.
   */
  const addVisitToStatistics = async (client) => {
    // Get the right referance (members or non members).
    const statisticsRef = client.isMember
      ? memberStatisticsRef
      : nonMemberStatisticsRef
    // Get a snapshot of statistics.
    const snapshot = await get(statisticsRef)
    // if statistics exists, add one to the value.
    if (snapshot.exists()) {
      const counterValue = await snapshot.val()
      const newCounterValue = parseInt(counterValue) + 1
      await set(snapshot.ref, newCounterValue)
      // if statistics does not exist, set the value to 1.
    } else {
      await set(statisticsRef, '1')
    }
  }

  // Shifts the queue one step forward.
  const next = async () => {
    // Get referance to the first client in the waiting list.
    let firstWaitingClientRef = await getFirstChildRef(waitingListRef)
    // If waiting list is not empty.
    if (firstWaitingClientRef) {
      // Get the first client in the waiting list.
      let firstWaitingClient = (await get(firstWaitingClientRef)).val()
      // Remove the first client in the waiting list.
      await remove(firstWaitingClientRef)
      // Add the first waiting client to the service list.
      const newClientRef = push(serviceListRef)
      await set(newClientRef, firstWaitingClient)
      // Get referance to the new first client in the waiting list.
      firstWaitingClientRef = await getFirstChildRef(waitingListRef)
      // Send reminder if required.
      handleSMSReminder(firstWaitingClientRef)
    } else {
      // If waiting list in empty, clear service list.
      await remove(serviceListRef)
    }

    // Limit the number people who is been serviced according to service capacity.
    const serviceLinstSnapshot = await get(serviceListRef)
    if (serviceLinstSnapshot.size > serviceCapacity) {
      const firstServiceClientRef = await getFirstChildRef(serviceListRef)
      await remove(firstServiceClientRef)
    }
  }

  // Removes a client from the waiting list.
  const removeFromQueue = async (docId) => {
    console.log(docId)
    // Get referance to the first client in the waiting list.
    let docReferance = child(waitingListRef, docId)
    remove(docReferance)
  }

  /**
   * Send SMS reminder to client, if required.
   * @param {object} clientRef - Referance to the client doc i realtime DB.
   */
  const handleSMSReminder = async (clientRef) => {
    if (!clientRef) return
    // Get the first client in the waiting list.
    const client = (await get(clientRef)).val()
    if (!client.smsReminder) return
    const headers = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getIdToken()}`,
      },
      body: JSON.stringify({ message: 'Hej! \nDu är först i kön!' }),
    }
    await fetch(
      `${process.env.REACT_APP_API_LINK}sms/send-to-one/${client.id}`,
      headers
    )
  }

  /**
   * Converts clients list from Firebase to an array.
   * @param {object} clientsObject Clients list.
   * @returns {Array} Clients list.
   */
  const objectToArray = (clientsObject) => {
    let clientsArray = []
    for (let docId in clientsObject) {
      clientsArray?.push({ docId, ...clientsObject[docId] })
    }
    return clientsArray
  }

  /**
   * Gets a reference to the first child in Firebase realtime DB.
   * @param {object} listRef Firebase list.
   * @returns A reference to the first child in the list.
   */
  const getFirstChildRef = async (listRef) => {
    // Get reference to the list with only the first client.
    const limtToFirstChildRef = query(listRef, limitToFirst(1))
    // Get snapshot of data for the retrieved list.
    const snapshot = await get(limtToFirstChildRef)
    // If the list is empty, return null.
    if (!snapshot.exists()) return null
    // Get reference for the first client in the list.
    const firstChildKey = Object.keys(snapshot.val())[0]
    const firstChildRef = child(listRef, firstChildKey)
    return firstChildRef
  }

  useEffect(() => {
    // Add listeners to waiting and serivce list.
    const unsubscribeWatingList = onValue(waitingListRef, (snapshot) => {
      const updatedWaitingList = snapshot.val()
      setWaitingList(objectToArray(updatedWaitingList))
    })
    const unsubscribServiceList = onValue(serviceListRef, (snapshot) => {
      // Skip updating the service list when it is longer than
      // service capacity because the list will be updated immediately.
      if (snapshot.size > serviceCapacity) return
      const updatedServiceList = snapshot.val()
      setServiceList(objectToArray(updatedServiceList))
    })
    const unsubscribStatistics = onValue(todaysStatisticsRef, (snapshot) => {
      const updatedStatistics = snapshot.val()
      setMemberStatistics(updatedStatistics?.member || 0)
      setNonMemberStatistics(updatedStatistics?.nonMember || 0)
    })
    // Detach listeners.
    return () => {
      unsubscribeWatingList()
      unsubscribServiceList()
      unsubscribStatistics()
    }
  }, [])

  return {
    next,
    addToQueue,
    setServiceCapacity,
    serviceCapacity,
    serviceList,
    waitingList,
    removeFromQueue,
    waitingListLength: waitingList.length,
    serviceListLength: serviceList.length,
    statistics: {
      members: parseInt(memberStatistics),
      nonMembers: parseInt(nonMemberStatistics),
      total: parseInt(nonMemberStatistics) + parseInt(memberStatistics),
    },
  }
}
