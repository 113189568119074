import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import CustomButton from '../../components/custom-button/CustomButton'
import ErrorMessage from '../../components/error-message/ErrorMessage'
import { useAuth } from '../../contexts/AuthContext'
import styles from './signIn.module.scss'

function SignInPage() {
  // Fields states.
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  // loading and errors states.
  const [loading, setLoading] = useState(false)
  const [fetchingError, setFetchingError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)

  // Sign in method.
  const { signIn } = useAuth()
  // Browsing navigation.
  const navigate = useNavigate()

  /**
   * Runs when sign in form is submitted.
   * @param {object} event - Submission event.
   */
  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!validate()) return
    try {
      setLoading(true)
      const user = await signIn(email, password)
      setLoading(false)
      // If the signing in is successful, rest fields values and redirect to home page.
      if (user) {
        setEmail('')
        setPassword('')
        navigate('/')
      }
    } catch (error) {
      setLoading(false)
      setFetchingError(localizeErrorMessage(error.code))
    }
  }

  /**
   * Validates user input.
   * @returns {boolean} - Validation results.
   */
  const validate = () => {
    let validInput = true
    resetErrors()
    const emailRegex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    if (!email || !emailRegex.test(email)) {
      setEmailError('E-post är inte gälltigt')
      validInput = false
    }
    if (!password) {
      setPasswordError('Fältet kan inte vare tomt')
      validInput = false
    }
    return validInput
  }

  /**
   * Resets all errors.
   */
  const resetErrors = () => {
    setFetchingError(null)
    setEmailError(null)
    setPasswordError(null)
  }

  /**
   * Localize sign-in error messages.
   */
  const localizeErrorMessage = (message) => {
    const messages = {
      'auth/user-not-found': 'Fel användarnamn eller lösenord',
      'auth/wrong-password': 'Fel användarnamn eller lösenord',
      'auth/timeout': 'Logga in tar längre tid än förväntat!',
      temporarily: 'Användaren verkar var avaktiverad',
    }
    if (message in messages) {
      return messages[message]
    } else {
      return 'Ett fel har inträffat. Vänligen försök igen senare'
    }
  }

  return (
    <div className="containerFullScreen">
      <form onSubmit={handleSubmit} className={styles.signInForm}>
        <h1>Logga in</h1>
        <label htmlFor="email" className={styles.label}>
          E-post
        </label>
        <input
          type="text"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <ErrorMessage message={emailError} />
        <label htmlFor="password" className={styles.label}>
          Lösenord
        </label>
        <input
          type="password"
          id="name"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <ErrorMessage message={passwordError} />
        <ErrorMessage message={fetchingError} />
        <CustomButton
          text="Logga in"
          theme="golden"
          pending={loading}
          onClick={handleSubmit}
        />
      </form>
    </div>
  )
}

export default SignInPage
