import React from 'react'
import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import styles from './discountCard.module.scss'

const DiscountCard = ({
  type,
  value,
  valueType,
  comment,
  expiration,
  _id,
  onActivate,
}) => {
  // Determine if the use button is open or closed.
  const [open, setOpen] = useState(false)

  // User token.
  let { getIdToken } = useAuth()

  // Closes or opens the use button.
  const toggleOpen = () => {
    setOpen((preState) => !preState)
  }

  /**
   * Activate this discount.
   */
  const activateDiscount = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_LINK}discounts/${_id}?state_event=activate`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${await getIdToken()}`,
          },
        }
      )
      const discount = await response.json()
      if (!response.ok || discount.state !== 'activated') {
        return
      }
      onActivate(_id)
    } catch (error) {
      // To be handled
    }
  }

  /**
   * Localize discount data.
   * @param {string} text - input text.
   * @returns {string} - localized text.
   */
  const localizeDiscontData = (text) => {
    const texts = {
      visit: 'Besök rabatt',
      gift: 'Gåva',
    }
    if (text in texts) {
      return texts[text]
    } else {
      return text
    }
  }

  return (
    <div className={styles.cardContainer}>
      <div className={styles.card} onClick={toggleOpen}>
        <div className={styles.discount}>
          <h4 className={styles.value}>{value + valueType}</h4>
          <span className={styles.expiration}>
            {expiration ? `T.o.m ${expiration.slice(0, 10)}` : 'Inget datum'}
          </span>
        </div>
        <div className={styles.description}>
          <h4 className={styles.type}>{localizeDiscontData(type)}</h4>
          <span className={styles.comment}>{comment}</span>
        </div>
      </div>
      <button
        className={
          open ? `${styles.useBtn} ${styles.useBtnActivated}` : styles.useBtn
        }
        onClick={() => activateDiscount(_id)}
      >
        Använd
      </button>
    </div>
  )
}

export default DiscountCard
