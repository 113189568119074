import { useState, useEffect } from "react"
import styles from "./offersList.module.scss"
import ControlOfferCard from "../control-offer-card/ControlOfferCard"
import { useAuth } from "../../contexts/AuthContext"

const OffersList = () => {
  // States.
  const [offers, setOffers] = useState([])

  // Get current user and id token.
  const { getIdToken, currentUser } = useAuth()

  /**
   * Get offers and update state accordingly.
   */
  const getOffers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_LINK}offers/all`,
        {
          headers: { Authorization: `Bearer ${await getIdToken()}` },
        }
      )
      const offers = await response.json()
      if (!response.ok) {
        return
      }
      console.log(offers)
      setOffers(offers)
    } catch (error) {
      // To be handled
      console.log(error.message)
    }
  }

  /**
   * Removes an offer from the List.
   */
  const removeOfferfromList = (offerId) => {
    const updatedList = offers.filter((offer) => offer._id !== offerId)
    setOffers(updatedList)
  }

  /**
   * Update the state of an offer in the List.
   */
  const updateOfferState = (offerId, newState) => {
    const updatedList = offers.map((offer) =>
      offer._id === offerId ? { ...offer, state: newState } : offer
    )
    setOffers(updatedList)
  }

  useEffect(() => {
    getOffers()
  }, [currentUser])

  return (
    <>
      {offers?.length > 0 ? (
        <ul className={styles.offers}>
          {offers?.map((offer) => (
            <ControlOfferCard
              key={offer._id}
              {...offer}
              removeOfferfromList={removeOfferfromList}
              updateOfferState={updateOfferState}
            />
          ))}
        </ul>
      ) : (
        <span className={styles.emptyMsg}>
          Det finns inga erbjudande add visa{" "}
        </span>
      )}
    </>
  )
}

export default OffersList
