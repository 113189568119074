import React from 'react'
import Spinner from '../spinner/Spinner'
import PropTypes from 'prop-types'

function CustomButton({ text, pending, theme, onClick }) {
  /**
   * Available themes.
   */
  const themes = ['grey', 'blackTransparent', 'black', 'golden']
  // Assign the chossen them to the button if it is available.
  // otherwise, black transparent theme.
  const buttonTheme = themes.includes(theme) ? theme : 'blackTransparent'
  // Assign spinner color accordingly.
  // This should be fixed in a better way.
  let spinnerColor = theme === 'golden' ? '#ffffff' : '#333333'
  return (
    <button
      style={{ pointerEvents: pending ? 'none' : 'unset' }}
      disabled={pending ? true : false}
      className={buttonTheme + 'Btn'}
      onClick={onClick}
    >
      {pending ? <Spinner color={spinnerColor} dimension="1.1rem" /> : text}
    </button>
  )
}

CustomButton.propTypes = {
  text: PropTypes.string,
  pending: PropTypes.bool,
  theme: PropTypes.string,
}

CustomButton.defaultProps = {
  text: '',
  pending: false,
  theme: 'black',
}

export default CustomButton
