import './App.scss'
import HomeRouter from './routers/HomeRouter'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './contexts/AuthContext'
import { FlashMessagesProvider } from './contexts/FlashMessagesContext'
import MenuBar from './components/menu-bar/MenuBar'
import FlashMessage from './components/flash-message/FlashMessage'

function App() {
  return (
    <AuthProvider>
      <FlashMessagesProvider>
        <BrowserRouter>
          <div className="app">
            <MenuBar />
            <FlashMessage />
            <HomeRouter />
          </div>
        </BrowserRouter>
      </FlashMessagesProvider>
    </AuthProvider>
  )
}

export default App
