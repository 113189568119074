import styles from './clientVisitsList.module.scss'

const ClientList = ({ visits }) => {
  const orderedVisits = visits?.sort((a, b) => {
    if (a.createdAt > b.createdAt) return -1
    if (a.createdAt < b.createdAt) return 1
    return 0
  })

  return (
    <>
      <h2 className={styles.title}>Besök</h2>
      {visits && visits?.length > 0 ? (
        <ul className={styles.visits}>
          {orderedVisits?.map((orderedVisits) => (
            <li key={orderedVisits._id} className={styles.visit}>
              <h3 className={styles.visitSerice}>{orderedVisits.service}</h3>
              <h3 className={styles.visitData}>
                {orderedVisits.createdAt?.slice(0, 10)}
              </h3>
            </li>
          ))}
        </ul>
      ) : (
        <span className={styles.emptyMsg}>Inget besök än så länge</span>
      )}
    </>
  )
}

export default ClientList
