import React, { useState } from 'react'
import styles from './editableText.module.scss'
import editIcon from './images/edit-icon.svg'
import okIcon from './images/ok-icon.svg'
import cancelIcon from './images/cancel-icon.svg'
function EditableText({ lable, text, bigFont, setNewText }) {
  // State declaration.
  const [editing, setEditing] = useState(false)
  const [editedText, setEditedText] = useState('')

  /**
   * Runs when the form is submitted.
   * @param {object} event - Submission event.
   */
  const handleSubmit = (event) => {
    event.preventDefault()
    setNewText(editedText)
    setEditing(false)
  }

  /**
   * Activates editing mode.
   */
  const activateEditing = () => {
    setEditedText(text || '')
    setEditing(true)
  }

  // If editing mode is active, return editing form.
  if (editing) {
    return (
      <form className={styles.info} onSubmit={handleSubmit}>
        <span className={lable ? styles.lable : 'hidden'}>{lable}</span>
        <input
          type="text"
          value={editedText}
          onChange={(e) => setEditedText(e.target.value)}
          className={styles.text}
        />
        <button className={styles.cancelBtn} onClick={() => setEditing(false)}>
          <img src={cancelIcon} alt="cancel icon" />
        </button>
        <button type="submit" onClick={handleSubmit} className={styles.okBtn}>
          <img src={okIcon} alt="ok icon" />
        </button>
      </form>
    )
  }

  return (
    <div className={styles.info}>
      <span className={lable ? styles.lable : 'hidden'}>{lable}</span>
      <span className={bigFont ? styles.bigText : styles.text}>{text}</span>
      <button className={styles.editBtn} onClick={activateEditing}>
        <img src={editIcon} alt="edit icon" />
      </button>
    </div>
  )
}

export default EditableText
