import React, { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { Navigate } from 'react-router-dom'

function Private({ children }) {
  // Permission status to the requested page.
  const [permission, setPermission] = useState('pedning')
  // Getting the current user
  let { currentUser, authLoading } = useAuth()

  useEffect(() => {
    // If there is a user, set the permission to ok
    if (currentUser) {
      setPermission('ok')
      return
    }
    // If there is no user and authentication is not in the process
    // Deny permission,
    if (!authLoading && !currentUser) {
      setPermission('denied')
    }
  }, [currentUser, authLoading])

  if (permission === 'ok') return children
  if (permission === 'pedning') return null
  if (permission === 'denied') return <Navigate to="/auth/signin" />
}

export default Private
