import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import ClientSearchPage from '../pages/client-search/ClientSearchPage'
import ClientViewPage from '../pages/client-view-page/ClientViewPage'
import NewClientPage from '../pages/new-client-page/NewClientPage'
import SignInPage from '../pages/sign-in-page/SignInPage'
import Private from '../custom-hooks/PrivateRoute'
import OffersPage from '../pages/offers-page/OffersPage'
import NewOfferPage from '../pages/new-offer-page/NewOfferPage'
import EditOfferPage from '../pages/edit-offer-page/editOfferPage'
import SendSmsPage from '../pages/send-sms-page/SendSmsPage'
import AddToQueuePage from '../pages/add-to-queue-page/AddToQueuePage'
import WaitingListPage from '../pages/waitingListPage/WaitingListPage'
import QueueDashboardPage from '../pages/queue-dashboard-page/QueueDashboardPage'

function HomeRouter() {
  const { currentUser } = useAuth()
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Private>
            <ClientSearchPage />
          </Private>
        }
      />
      <Route
        path="/search"
        element={
          <Private>
            <ClientSearchPage />
          </Private>
        }
      />
      <Route
        path="/auth/signin"
        element={currentUser ? <Navigate to="/" /> : <SignInPage />}
      />
      <Route
        path="/clients/add"
        element={
          <Private>
            <NewClientPage />
          </Private>
        }
      />
      <Route
        path="/clients/:id"
        element={
          <Private>
            <ClientViewPage />
          </Private>
        }
      />
      <Route
        path="/offers/add"
        element={
          <Private>
            <NewOfferPage />
          </Private>
        }
      />
      <Route
        path="/offers/edit/:id"
        element={
          <Private>
            <EditOfferPage />
          </Private>
        }
      />
      <Route
        path="/sms/send"
        element={
          <Private>
            <SendSmsPage />
          </Private>
        }
      />
      <Route
        path="/offers"
        element={
          <Private>
            <OffersPage />
          </Private>
        }
      />
      <Route
        path="/queue/register"
        element={
          <Private>
            <AddToQueuePage />
          </Private>
        }
      />
      <Route path="/queue/show" element={<WaitingListPage />} />
      <Route
        path="/queue/dashboard"
        element={
          <Private>
            <QueueDashboardPage />
          </Private>
        }
      />
    </Routes>
  )
}

export default HomeRouter
