import {React,} from 'react'
import styles from './toggleTwoValuesInput.module.scss'

function ToggleTwoValuesInput({valueOne, valueTwo, setValue}) {
  
  /**
	 * Runs when the chosen is changed.
	 * @param {object} event - Submission event.
	 */
  const handelChange = (event) => {
   setValue(event.target.checked? valueTwo : valueOne)
  }

  return (
    <label className={styles.switch}>
      <input type='checkbox' id='valueType' onChange={handelChange}/>
      <div className={styles.values}>
        <span className={styles.slider}/>
        <span className={styles.valueOne}>%</span>
        <span className={styles.valueTwo}>kr</span>
      </div>
    </label>
  )
}

export default ToggleTwoValuesInput
